<template>
    <RouterLink id="wrapper" :to="'/products/' + id">
        <img :src="require('@/assets/' + imageUrl)">
        <div id="text">
            <h3>{{ productName }}</h3>
            <h4>Ab {{ price }}€</h4>
        </div>
    </RouterLink>
</template>

<script>
import { RouterLink } from 'vue-router';

export default {
    props: ['imageUrl', 'productName', 'id', 'price'],
    components: { RouterLink }
}
</script>

<style scoped>
img {
    aspect-ratio: 945/1329;
}

#wrapper{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0.75rem;
}

#text {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0.1rem;
}

h3 {
    font-weight: 600;
    text-align: center;
}

h4 {
    font-weight: 500;
}
</style>
<template>
  <swiper
    :navigation="true"
    id="main_swiper"
    :modules="modules"
    class="mySwiper"
  >
    <swiper-slide>
      <div class="bg-waves bg-cover h-[45svh] md:h-[65svh] flex justify-center md:justify-start lg:justify-around items-center px-4 mx-auto relative" v-on:click="$router.push('mix-it')">
        <div id="text" class="z-10">
          <h3 class="text-[#dfad2e] text-5xl">Neu:</h3>
          <h1 class="font-bold text-6xl md:text-8xl 2xl:text-9xl rubik max-lg:drop-shadow-md text-darkPrimary">
            MISCH<br />ES<br />SELBST
          </h1>
        </div>
        <img
          class="h-[75svh] md:block hidden rotate-[7deg] translate-y-[-48%] translate-x-[-10%] absolute lg:static lg:translate-y-[2%] lg:translate-x-[0%] left-[50%] top-[50%] z-[0]"
          src="../../../assets/feature-slideshow/mix-it/Produktdesign_1.png"
        />
      </div>
    </swiper-slide>
    <swiper-slide>
      <div class="bg-waves bg-cover h-[45svh] md:h-[65svh] flex justify-center md:justify-start lg:justify-around items-center px-4 mx-auto relative" v-on:click="$router.push('mix-it')">
        <div id="text" class="z-10">
          <h3 class="text-[#dfad2e] text-5xl">Neu:</h3>
          <h1 class="font-bold text-6xl md:text-8xl 2xl:text-9xl rubik max-lg:drop-shadow-md text-darkPrimary">
            MISCH<br />ES<br />SELBST
          </h1>
        </div>
        <img
          class="h-[75svh] md:block hidden rotate-[7deg] translate-y-[-48%] translate-x-[-10%] absolute lg:static lg:translate-y-[2%] lg:translate-x-[0%] left-[50%] top-[50%] z-[0]"
          src="../../../assets/feature-slideshow/mix-it/Produktdesign_1.png"
        />
      </div>
    </swiper-slide>
  </swiper>
</template>

<script>
// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from "swiper/vue";

// Import Swiper styles
import "swiper/css";

import "swiper/css/navigation";

// import required modules
import { Navigation } from "swiper/modules";

export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  setup() {
    return {
      modules: [Navigation],
    };
  },
};
</script>

<style scoped>
#main_swiper {
  height: 65svh;
  position: relative;
  background-size: cover;
}

@media not all and (min-width: 768px) {
  #main_swiper {
  height: 45svh;
}
}
</style>

<template>
  <div class="w-full py-16 lg:py-32 h-full">
    <div class="max-w-7xl space-y-12 mx-auto px-8">
      <h2 class="text-center font-bold text-4xl lg:text-5xl">Unsere Empfehlungen</h2>
      <ul
        class="w-full mx-auto px-8 grid md:grid-cols-2 grid-cols-1 lg:grid-cols-3 gap-10 lg:gap-20 xl:gap-40"
      >
        <li v-for="product in getFeaturedProducts" :key="product.id" class="max-w-[300px] mx-auto">
          <FeaturedProduct
            :imageUrl="product.imageUrl"
            :productName="product.name"
            :price="product.sizes[0].price"
            :id="product.id"
          ></FeaturedProduct>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  inject: ["products"],
  computed: {
    getFeaturedProducts() {
      return this.products.filter((product) => product.id < 3);
    },
  },
};
</script>

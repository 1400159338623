<template>
    <h1>Registrieren</h1>
    <form v-on:submit.prevent>
        <div>
            <h2>Email:</h2>
            <input type="email" v-model="email">
        </div>
        <div>
            <h2>Passwort:</h2>
            <input type="password" v-model="password">
        </div>
        <div>
            <h2>Gebe erneut dein Passwort ein:</h2>
            <input type="password" v-model="passwordConfirm">
        </div>
        <ButtonStyle>
            <h3>Account erstellen</h3>
        </ButtonStyle>
    </form>
    <p>Du hast schon einen Account?<br>Melde dich <RouterLink to="login">hier</RouterLink> an.</p>
</template>

<script>
export default {
    name: 'LogInView',
    data() {
        return {
            email: '',
            password: '',
            passwordConfirm: ''
        };
    },
    mounted() {
        window.scrollTo(0, 0);
    }
}
</script>
<template>
  <div class="pb-16 pt-8">
    <ProductsTitle></ProductsTitle>
    <OurProducts></OurProducts>
    <MixItSection></MixItSection>
  </div>
</template>

<script>
import ProductsTitle from "../components/products/ProductsTitle.vue";
import OurProducts from "../components/products/OurProducts.vue";
import MixItSection from "../components/products/MixItSection.vue";

export default {
  name: "ProductsView",
  components: {
    ProductsTitle: ProductsTitle,
    OurProducts: OurProducts,
    MixItSection: MixItSection,
  },
  mounted() {
    window.scrollTo(0, 0);
  }
};
</script>

<template>
    <div :style="{ backgroundColor : color}" class="px-8 py-8 rounded-3xl bg-primary">
        <slot></slot>
    </div>
</template>

<script>
export default {
    props: ['color']
}
</script>
<template>
  <div>
    <h1>Unsere Produkte</h1>
    <h4>Ein kurzer Untertitel</h4>
  </div>
</template>

<style scoped>
div {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 0.25rem;
}

h1 {
  color: var(--clr-green-dark);
  font-weight: 700;
  font-size: var(--size-4xl);
}

h4 {
  color: var(--clr-green-light);
  font-weight: 300;
  font-size: var(--size-xl);
}
</style>

<template>
  <div>
    <h2>{{ title }}</h2>
    <ul class="list-none m-0 p-0 h-full flex justify-center items-start gap-20 flex-wrap">
      <li v-for="product in products" :key="product.name">
        <FeaturedProduct
            :imageUrl="product.imageUrl"
            :productName="product.name"
            :id="product.id"
            :price="product.sizes[0].price"
          ></FeaturedProduct>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: ["title", "products"],
};
</script>

<style scoped>
div {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 2rem;
  flex-wrap: wrap;
}

h2 {
  color: var(--clr-green-dark);
  font-size: var(--size-4xl);
}

li {
  width: 12rem;
}
</style>

<template>
    <button>
        <slot></slot>
    </button>
</template>

<style scoped>
button {
    background: var(--clr-green-light);
    padding: var(--size-xxs);
    border-radius: 6px;
    transition: all .2s;
}

button:hover {
    background: var(--clr-green-dark);
}
</style>
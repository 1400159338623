<template>
  <section class="bg-[#F5FFDE]/30 flex flex-col min-h-[100dvh]">
    <TheNavigation class="bg-white"></TheNavigation>
    <main class="flex-1">
      <RouterView></RouterView>
    </main>
    <TheFooter class="section"></TheFooter>
  </section>
</template>

<script>
import TheNavigation from "./components/nav/TheNavigavtion.vue";
import TheFooter from "./components/nav/TheFooter.vue";
//get current path

export default {
  components: {
    TheNavigation: TheNavigation,
    TheFooter: TheFooter,
  },
  data() {
    return {
      products: [
        {
          id: "0",
          name: "Klassisch",
          imageUrl: "Produktdesign.png",
          category: "classic",
          sizes: [
            {
              name: "small",
              amount: "500g",
              price: "4,49",
            },
            {
              name: "big",
              amount: "1000g",
              price: "7,99",
            },
          ],
          shortDescription: "Kurze Beschreibung...",
          sellingPoints: ["> Gut", "> Günstig"],
          longDescription: "Lange Beschreibung hier...",
        },
        {
          id: "1",
          name: "Früchtemüsli 1",
          imageUrl: "Produktdesign.png",
          category: "fruit",
          sizes: [
            {
              name: "small",
              amount: "500g",
              price: "6,99",
            },
            {
              name: "big",
              amount: "1000g",
              price: "12,99",
            },
          ],
          shortDescription: "Kurze Beschreibung...",
          sellingPoints: ["> Gut", "> Günstig"],
          longDescription: "Lange Beschreibung hier...",
        },
        {
          id: "2",
          name: "Schokomüsli",
          imageUrl: "Produktdesign.png",
          category: "sweet",
          sizes: [
            {
              name: "small",
              amount: "500g",
              price: "5,99",
            },
            {
              name: "big",
              amount: "1000g",
              price: "10,99",
            },
          ],
          shortDescription: "Kurze Beschreibung...",
          sellingPoints: ["> Gut", "> Günstig"],
          longDescription: "Lange Beschreibung hier...",
        },
      ],
      productPrices: [
        {
          category: "mixit",
          small: "9,99",
          big: "16,99",
        },
      ],
    };
  },
  provide() {
    return {
      products: this.products,
      prices: this.productPrices,
    };
  },
  computed: {
    currentRoute() {
      console.log(this.$route.path);
      return this.$route.path;
    },
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&family=Rubik:wght@300;400;500;600;700;800;900&display=swap");

:root {
  --size-xxs: 0.5rem;
  --size-xs: 0.75rem;
  --size-sm: 0.875rem;
  --size-base: 1rem;
  --size-lg: 1.125rem;
  --size-xl: 1.25rem;
  --size-2xl: 1.5rem;
  --size-3xl: 1.875rem;
  --size-4xl: 2.25rem;
  --size-5xl: 3rem;
  --size-6xl: 3.75rem;
  --size-7xl: 4.5rem;
  --size-8xl: 6rem;
  --size-9xl: 8rem;
  --size-10xl: 10rem;

  --clr-green-light: #a7c957;
  --clr-green-dark: #31572c;
  --clr-yellow: #eae547;
}

* {
  margin: 0;
  box-sizing: border-box;
  font-family: "Rubik", sans-serif;
}

body {
  min-height: 100dvh;
}

img,
picture,
video,
canvas,
svg {
  display: block;
  max-width: 100%;
  user-select: none;
}

button {
  display: inline-block;
  padding: 0;
  border: none;
  background: none;
  cursor: pointer;
  color: inherit;
}

.container {
  margin-left: auto;
  margin-right: auto;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.section {
  padding-top: 7rem;
}
</style>

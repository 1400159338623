<template>
  <div class="grid lg:grid-cols-2 px-4 gap-4 max-w-7xl mx-auto">
    <div class="grid gap-4">
      <BaseCard class="card">
        <h2 class="text-xl sm:text-2xl md:text-3xl font-semibold text-center whitespace-nowrap textColor">
          Ein erfrischender<br />Start in den Tag
        </h2>
      </BaseCard>
      <BaseCard class="card" color="var(--clr-yellow)">
        <h2 class="text-xl md:text-3xl font-semibold text-center whitespace-nowrap">
          100%<br />Kundenzufriedenheit
        </h2>
      </BaseCard>
    </div>
    <BaseCard id="grid-right" color="var(--clr-green-dark)" class="flex justify-center items-center flex-col gap-4 md:gap-8">
      <h2 class="text-xl md:text-3xl font-semibold text-center whitespace-nowrap textColor">
        Lebe gesünder mit<br />unserem Vergleich
      </h2>
      <button
        class="text-xl md:text-3xl font-semibold text-center whitespace-nowrap hover:border-darkPrimary hover:bg-[#eae547] hover:text-black transition-all"
        v-on:click="$router.push('/compare')"
      >
        Jetzt testen >
      </button>
    </BaseCard>
  </div>
</template>

<style scoped>
button {
  border: 2px solid var(--clr-yellow);
  border-radius: 100px;
  color: white;
  width: auto;
  margin: 0 auto;
  padding: 0.75rem 1.25rem;
  font-weight: 500;
}

.card {
  display: flex;
  align-items: center;
  justify-content: center;
}

.textColor {
  color: white;
}
</style>

<template>
  <div class="py-12" id="footer">
    <div
      class="sm:max-w-7xl max-w-xs mx-auto flex flex-col-reverse sm:flex-row gap-8 px-4 items-start justify-center"
    >
      <div id="footer-information flex items-start flex-col  mx-auto">
        <div id="footer-information-header">
          <img src="@/assets/logo.svg" />
          <h2>Hanfonie</h2>
        </div>
        <p id=" " class="text-[#FFFEDD]">
          Dein Anbieter für gesundes Hanf Müsli
        </p>
        <p id="footer-information-copyright" class="mt-5">
          Copyright © 2024 Hanfonie
        </p>
      </div>

      <div class="grid sm:flex flex-row gap-8 flex-wrap items-start justify-center">
        <FooterAsset title="Produkte" :links="products"></FooterAsset>
        <FooterAsset title="Mein Konto" :links="account"></FooterAsset>
        <FooterAsset title="Unternehmen" :links="business"></FooterAsset>
        <FooterAsset title="Informationen" :links="information"></FooterAsset>
      </div>
    </div>
  </div>
</template>

<script>
import FooterAsset from "./utils/FooterAsset";

export default {
  components: {
    FooterAsset: FooterAsset,
  },
  data() {
    return {
      products: [
        {
          url: "/products",
          text: "Unser Sortiment",
        },
        {
          url: "/mix-it",
          text: "Mix it yourself",
        },
      ],
      account: [
        {
          url: "/login",
          text: "Login/Registrierung",
        },
        {
          url: "/cart",
          text: "Warenkorb",
        },
      ],
      business: [
        {
          url: "/about",
          text: "Über uns",
        },
      ],
      information: [
        {
          url: "/agb",
          text: "AGB",
        },
        {
          url: "/faq",
          text: "FAQ",
        },
        {
          url: "/datenschutz",
          text: "Datenschutz",
        },
        {
          url: "/impressum",
          text: "Impressum",
        },
      ],
    };
  },
};
</script>

<style scoped>
#footer {
  background: linear-gradient(190deg, #fffedd 20%, #b9d478 80%);
}

#footer-information-header {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: start;
  gap: 0.25rem;
}

img {
  aspect-ratio: 1/1;
  width: var(--size-9xl);
}

h2 {
  font-size: var(--size-3xl);
  font-weight: 600;
  color: var(--clr-green-dark);
}

#footer-information-description {
  color: white;
  font-weight: 400;
  font-size: var(--size-xl);
  letter-spacing: 0.03rem;
}

#footer-information-copyright {
  color: black;
  font-weight: 400;
  font-size: var(--size-sm);
}
</style>

import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import "./index.css";

import ButtonStyle from "./components/ui/ButtonStyle.vue";
import FeaturedProduct from "./components/ui/FeaturedProduct.vue";
import BaseCard from "./components/ui/BaseCard.vue";

const app = createApp(App);

app.component("ButtonStyle", ButtonStyle);
app.component("FeaturedProduct", FeaturedProduct);
app.component("BaseCard", BaseCard);

app.use(router);

app.mount("#app");

<template>
    <ProductDetails :product="product"></ProductDetails>
</template>

<script>
import ProductDetails from '@/components/productDetails/ProductDetails.vue';

export default {
    name: 'ProductDetailsView',
    components: {
        ProductDetails: ProductDetails
    },
    inject: ['products'],
    props: ['productId'],
    data() {
        return {
            product: {},
        };
    },
    created() {
        const id = this.$route.params.productId;
        this.product = this.products.find(product => product.id === id);
        window.scrollTo(0, 0);
    }
}
</script>
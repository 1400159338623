<template>
  <div class="px-4" style="margin-top: 4rem">
    <ProductSection
      class="product-section"
      title="Die Classics"
      :products="getClassics"
    ></ProductSection>
    <ProductSection
      class="product-section"
      title="Unsere Sorten mit Obst"
      :products="getFruits"
    ></ProductSection>
    <ProductSection
      class="product-section"
      title="Was Süßes"
      :products="getSweets"
    ></ProductSection>
  </div>
</template>

<script>
import ProductSection from "./ProductSection.vue";

export default {
  components: {
    ProductSection: ProductSection,
  },
  inject: ["products"],
  computed: {
    getClassics() {
      return this.products.filter((product) => product.category === "classic");
    },
    getFruits() {
      return this.products.filter((product) => product.category === "fruit");
    },
    getSweets() {
      return this.products.filter((product) => product.category === "sweet");
    },
  },
};
</script>

<style scoped>
.product-section {
  margin-bottom: 3rem;
}
</style>

<template>
  <ul
    class="list-none p-0 m-0 flex gap-8 mb-10 lg:mb-0 overflow-auto lg:flex-col justify-start items-start lg:gap-3 cursor-default"
  >
    <li
      class="cursor-pointer whitespace-nowrap gap-8"
      v-for="title in titles"
      :key="title.id"
      v-on:mouseenter="onElementHover(title.id)"
    >
      <h3 :class="{ activeElement: activeElement === title.id }">
        {{ title.name }}
      </h3>
    </li>
  </ul>
</template>

<script>
export default {
  emits: ["getActiveElement"],
  data() {
    return {
      activeElement: "0",
      titles: [
        {
          id: "0",
          name: "Geschäftsidee",
        },
        {
          id: "1",
          name: "Schlüsselaktivitäten",
        },
        {
          id: "2",
          name: "Standortwahl und Räumlichkeiten",
        },
        {
          id: "3",
          name: "Schlüsselressourcen",
        },
        {
          id: "4",
          name: "Unternehmensform",
        },
        {
          id: "5",
          name: "Schlüsselpartner",
        },
        {
          id: "6",
          name: "Kundengruppen",
        },
        {
          id: "7",
          name: "Kundenbeziehungen und Marketing",
        },
        {
          id: "8",
          name: "Finanzen",
        },
      ],
    };
  },
  methods: {
    onElementHover(id) {
      this.activeElement = id;
      this.$emit("getActiveElement", this.activeElement);
    },
  },
};
</script>

<style scoped>
h3 {
  font-size: var(--size-lg);
  font-weight: 400;
}

.activeElement {
  font-weight: 600;
}
</style>

<template>
    <li class="rounded-lg border-2 border-neutral-300 text-neutral-400" :class="{active: modelValue == size}" v-for="size in sizes" :key="size">
        <button v-on:click="activate(size)">
            <h4 class="mx-2">{{ size.amount }}</h4>
        </button>
    </li>
</template>

<script>
export default {
    props: ['sizes', 'modelValue'],
    emits: ['update:modelValue'],
    methods: {
        activate(size) {
            this.$emit('update:modelValue', size);
        }
    },
}
</script>

<style scoped>
.active {
    color: var(--clr-green-light);
    border-color: var(--clr-green-light);
}
</style>
<template>
  <div id="wrapper-horizontal" class="max-w-7xl px-4 mx-auto py-24">
    <div id="wrapper-vertical">
      <div id="description">
        <h1 class="text-4xl font-bold text-primary">Etwas über uns:</h1>
        <p class="max-w-4xl">
          Hallo, wir sind ein junges Startup, welches sich zur Aufgabe gemacht
          hat, dein Müsli gesünder zu machen. Durch unser Müsli hast du einen
          guten und ausgewogenen Start in den Tag und durch den einzigartigen
          Geschmack der Hanf Produkte, welche unsere Müslis enthalten, können
          wir dir noch zusätzlich deine Laune versüßen.
        </p>
      </div>
      <RouterLink v-if="showLink" class="text-darkPrimary mt-8" to="/about"
        >Erfahre mehr ></RouterLink
      >
    </div>
    <img
      class="absolute right-2 bottom-4 lg:bottom-[70px] h-20 lg:h-56"
      src="@/assets/logo.svg"
      v-if="false"
    />
  </div>
</template>

<script>
export default {
  props: {
    showLink: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
};
</script>

<style scoped>
#wrapper-horizontal {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 5rem;

  height: 100%;
  width: 100%;
}

img {
  aspect-ratio: 1/1;
}

#wrapper-vertical {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: space-between;
  height: 100%;
}

#description {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;
  gap: 1rem;
}

p {
  font-size: var(--size-base);
}
</style>

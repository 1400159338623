<template>
    <div>
        <h2>{{ title }}</h2>
        <input class="px-2 outline-none border-0 border-b-2 border-neutral-500 bg-inherit" :type="type" v-model="value">
    </div>
</template>

<script>
export default {
    props: ['title', 'type'],
    emits: ['update:modelValue'],
    data() {
        return {
            value: '',
        };
    },
    watch: {
        value() {
            this.$emit('update:modelValue', this.value);
        }
    }
}
</script>
<template>
  <ul class="flex flex-col space-y-1">
    <li v-for="sellingPoint in sellingPoints" :key="sellingPoint">
      <p>{{ sellingPoint }}</p>
    </li>
  </ul>
</template>

<script>
export default {
  props: ["sellingPoints"],
};
</script>

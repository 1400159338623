<template>
    <div class="flex flex-col justify-center items-center gap-8 py-8 basis-full">
        <h1 class="text-2xl font-medium text-darkPrimary">Log In</h1>
        <div class="flex flex-col items-start justify-center gap-4 p-8 bg-[#FFFFFF]/20 shadow rounded-lg border border-spacing-0">
            <form class="flex flex-col items-start justify-center gap-4" v-on:submit.prevent>
                <LogInInput :title="'Email:'" :type="'email'" v-model="email"></LogInInput>
                <LogInInput :title="'Passwort:'" :type="'password'" v-model="password"></LogInInput>
                <ButtonStyle>
                    <h3>Log In</h3>
                </ButtonStyle>
            </form>
            <p>Du hast noch keinen Account?<br>Erstelle dir jetzt <RouterLink to="signup">hier</RouterLink> einen.</p>
        </div>
    </div>
</template>

<script>
import LogInInput from '../components/logIn/LogInInput.vue'

export default {
    name: 'LogInView',
    components: {
        LogInInput: LogInInput
    },
    data() {
        return {
            email: '',
            password: ''
        };
    },
    mounted() {
        window.scrollTo(0, 0);
    }
}
</script>
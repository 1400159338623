<template>
  <div
    class="flex flex-col items-center justify-center gap-6 max-w-7xl mx-auto px-4"
  >
    <h2 class="text-4xl font-medium">Gestalte dein Erlebnis selbst</h2>
    <ButtonStyle v-on:click="$router.push('mix-it')">
      <h3 class="text-3xl text-white mx-10 my-2">Mix it yourself</h3>
    </ButtonStyle>
    <h4 class="text-xl font-medium">Für nur 9,99€</h4>
  </div>
</template>

<style scoped>
h2 {
  color: var(--clr-green-dark);
}
</style>

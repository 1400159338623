<template>
  <header class="h-[97px] flex items-center max-w-full justify-center px-4">
    <div class="max-w-8xl mx-auto w-full flex items-center justify-between">
      <Menu as="div" class="relative lg:hidden inline-block text-left">
        <MenuButton class="lg:hidden block">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="#A7C957"
            class="w-6 h-6 lg:hidden block"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
            />
          </svg>
        </MenuButton>
        <transition
          enter-active-class="transition ease-out duration-100"
          enter-from-class="transform opacity-0 scale-95"
          enter-to-class="transform opacity-100 scale-100"
          leave-active-class="transition ease-in duration-75"
          leave-from-class="transform opacity-100 scale-100"
          leave-to-class="transform opacity-0 scale-95"
        >
          <MenuItems
            class="absolute z-10 mt-6 left-0 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
          >
            <div class="py-1">
              <ul class="flex flex-col px-4 space-y-3 py-3 items-start">
                <li>
                  <RouterLink to="/">Home</RouterLink>
                </li>
                <li>
                  <RouterLink to="/products">Produkte</RouterLink>
                </li>
                <li>
                  <RouterLink to="/about">Über uns</RouterLink>
                </li>
              </ul>
            </div>
          </MenuItems>
        </transition>
      </Menu>
      <div class="lg:flex hidden items-center gap-4 cursor-pointer" v-on:click="$router.push('/')">
        <img
          src="../../assets/logo.svg"
          class="sm:h-14 h-8 w-auto"
          id="logo-img"
        />
        <h1 class="sm:text-4xl text-2xl font-bold text-[#A7C957]">Hanfonie</h1>
      </div>
      <div class="lg:flex hidden items-center gap-4 justify-between">
        <ul class="ul">
          <li>
            <RouterLink to="/">Home</RouterLink>
          </li>
          <li>
            <RouterLink to="/products">Produkte</RouterLink>
          </li>
          <li>
            <RouterLink to="/about">Über uns</RouterLink>
          </li>
        </ul>
      </div>
      <div class="lg:hidden flex items-center gap-0 sm:gap-4 cursor-pointer" v-on:click="$router.push('/')">
        <img
          src="../../assets/logo.svg"
          class="h-8 sm:h-14 w-auto"
          id="logo-img"
        />
        <h1 class="sm:text-4xl text-2xl font-bold text-[#A7C957]">Hanfonie</h1>
      </div>
      <div class="flex items-center gap-4">
        <ButtonStyle id="cart-button">
          <h4 class="sm:block hidden">Warenkorb</h4>
          <img src="../../assets/cart.svg" class="size-5 sm:size-auto" />
        </ButtonStyle>
        <ButtonStyle v-on:click="$router.push('/login')">
          <img src="../../assets/user.svg" class="size-5 sm:size-auto" />
        </ButtonStyle>
      </div>
    </div>
  </header>
</template>

<script>
import { Menu, MenuButton, MenuItems } from "@headlessui/vue";

export default {
  components: {
    Menu,
    MenuButton,
    MenuItems,
  },
};
</script>

<style scoped>
#logo-img {
  aspect-ratio: 1/1;
  width: var(--size-6xl);
}

.ul {
  list-style: none;
  margin: 0;
  padding: 0;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;
}

a {
  text-decoration: none;
  color: var(--clr-green-dark);
  font-weight: 600;
  font-size: var(--size-xl);
  transition: all 0.2s;
}

a:hover {
  color: var(--clr-green-light);
}

button {
  color: white;
}

h4 {
  font-weight: 500;
  font-size: var(--size-base);
}

#cart-button {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: var(--size-xxs);
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

header {
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
}
</style>

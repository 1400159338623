<template>
    <div>
        <h3>{{ title }}</h3>
        <ul>
            <li v-for="link in links" :key="link.url">
                <RouterLink :to="link.url">{{ link.text }}</RouterLink>
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    props: ['title', 'links']
}
</script>

<style scoped>
div {
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: start;
    gap: 1.5rem;
}

h3 {
    font-weight: 600;
    font-size: var(--size-xl);
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  gap: 0.5rem;
}

a {
    font-weight: 500;
    font-size: var(--size-base);
    text-decoration: none;
    color: inherit;
}
</style>
<template>
    <li class="w-auto flex justify-center items-center" v-for="option in filteredOptions" :key="option">
        <button v-on:click="activate(option)">
            <h2 class="border-2 rounded-md border-neutral-400 px-3 py-2 text-center font-normal" :class="{active: modelValue == option}">{{ option }}</h2>
        </button>
    </li>
</template>

<script>
export default {
    props: ['options', 'search', 'modelValue'],
    emits: ['update:modelValue'],
    data() {
        return {
            filteredOptions: null,
        };
    },
    methods: {
        activate(option) {
            this.$emit('update:modelValue', option);
        }
    },
    watch: {
        search() {
            if(this.search.trim() === '' || this.search === null) {
                this.filteredOptions = this.options;
            } else {
                this.filteredOptions = this.options.filter(option => option.toLowerCase().includes(this.search.toLowerCase()));
            }
        }
    },
    created() {
        this.filteredOptions = this.options;
    }
}
</script>

<style scoped>
.active {
    color: var(--clr-green-light);
    border-color: var(--clr-green-light);
    font-weight: 500;
}
</style>
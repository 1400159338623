<template>
  <!-- <CarouselView></CarouselView> -->
  <component :is="slideShowElements[activeSlideshowElement]"></component>
  <FeaturedProducts></FeaturedProducts>
  <HomeGrid></HomeGrid>

  <AboutUs showLink="true"></AboutUs>
</template>

<script>
import MixIt from "../components/home/slideshow/MixIt.vue";
import FeaturedProducts from "../components/home/FeaturedProducts.vue";
import HomeGrid from "../components/home/HomeGrid.vue";
import AboutUs from "../components/about/AboutUs.vue";
// import CarouselView from "../components/CarouselView";

export default {
  name: "HomeView",
  components: {
    MixIt: MixIt,
    FeaturedProducts: FeaturedProducts,
    HomeGrid: HomeGrid,
    AboutUs: AboutUs,
    // CarouselView: CarouselView,
  },
  data() {
    return {
      activeSlideshowElement: 0,
      slideShowElements: ["MixIt"],
    };
  },
  mounted() {
    window.scrollTo(0, 0);
  }
};
</script>

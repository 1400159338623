<template>
  <div class="max-w-8xl lg:w-11/12 xl:w-11/12 mx-8 sm:mx-24 md:mx-40 lg:mx-auto pt-12 px-4">
    <div class="flex flex-col lg:flex-row gap-8 md:gap-11 xl:gap-14">
      <img
        class="max-h-[380px] lg:max-h-[450px] aspect-product mx-auto"
        :src="require('@/assets/' + product.imageUrl)"
      />
      <div class="flex flex-col gap-1 flex-1">
        <h2 class="font-medium text-3xl text-start text-darkPrimary">
          {{ product.name }}
        </h2>
        <hr class="h-0.5 border-t-0 bg-gray-200 opacity-100" />
        <p class="text-start mt-2">{{ product.shortDescription }}</p>
        <ProductDetailsList class="mt-2"
          :sellingPoints="product.sellingPoints"
        ></ProductDetailsList>
      </div>
      <div class="flex lg:self-center px-0 py-4 lg:p-4 items-center flex-col justify-center gap-3 bg-[#FFFFFF]/20 shadow rounded-lg border border-spacing-0">
          <h3 class="text-xl font-semibold">{{ size.price }}€</h3>
          <ul class="flex flex-row gap-3 font-medium text-base ">
            <ProductDetailsSizes :sizes="product.sizes" v-model="size"></ProductDetailsSizes>
          </ul>
          <ButtonStyle>
            <h4 class="px-1 sm:px-5 text-sm lg:text-xs xl:text-sm text-white font-medium">
              Zum Warenkorb hinzufügen
            </h4>
          </ButtonStyle>
        </div>
    </div>
    <p class="py-16">{{ product.longDescription }}</p>
  </div>
</template>

<script>
import ProductDetailsList from "./ProductDetailsList.vue";
import ProductDetailsSizes from "./ProductDetailsSizes.vue";

export default {
  props: ["product"],
  components: {
    ProductDetailsList: ProductDetailsList,
    ProductDetailsSizes: ProductDetailsSizes
  },
  data() {
    return {
      size: {},
    };
  },
  created() {
    this.size = this.product.sizes[0];
  }
};
</script>

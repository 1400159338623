<template>
  <div class="max-w-7xl grid px-4 grid-cols-1 lg:grid-cols-3 mx-auto">
    <ProjectTextTabTitles
      v-on:get-active-element="setDescriptionText"
    ></ProjectTextTabTitles>
    <p class="pre-formatted lg:col-span-2">{{ activeDescription }}</p>
  </div>
</template>

<script>
import ProjectTextTabTitles from "./ProjectTextTabTitles.vue";

export default {
  components: {
    ProjectTextTabTitles: ProjectTextTabTitles,
  },
  data() {
    return {
      activeDescription: "",
      descriptions: [
        {
          id: "0",
          name: "Seit einigen Jahren achten viele Menschen immer stärker auf eine nachhaltige und gesunde Ernährung. Dabei spielen vermehrt Superfoods wie Hanfsamen eine große Rolle. Daher ist es nicht weit hergeholt, dass viele Menschen auf der Suche nach einem einfachen und gesunden Start in den Tag sind. Hanfonie schafft nun Abhilfe dafür: Unglaublich leckere Müslis mit gesunden Zutaten aus nachhaltigem Anbau. Wer möchte, kann sich sein Müsli sogar vollkommen individuell zusammenstellen.\nDie Besonderheit: Die Müslis von Hanfonie werden durch das perfekte Verhältnis von Hanfsamen zu einem echten Erlebnis aufgewertet. Die Hanfpflanze kann nämlich nicht nur für das alltägliche Kiffen von Nutzen sein. Nein, ihre Samen sind wahre Gesundheitswunderwerke der Natur.",
        },
        {
          id: "1",
          name: "Die bisherigen Schlüsselaktivitäten von Hanfonie sind vielseitig. Unter anderem die formale Gründung des Unternehmens ist essenziell für jegliche weitere Schritte. Um schließlich tatsächlich agieren zu können müssen verschiedene Finanzierungsmöglichkeiten in Betracht gezogen werden. Auch die Suche nach einer geeigneten Bürofläche ist wichtig. Allein diese wenigen Aktivitäten zeigen, dass sich die Gründer von Hanfonie über viele verschiedene Dinge Gedanken machen mussten und weiterhin machen.",
        },
        {
          id: "2",
          name: "Klar ist, dass wir nicht die Möglichkeit haben, direkt mit einer großen Fabrik ins Geschäft zu starten. Um trotzdem möglichst gut arbeiten zu können, bietet es sich zu Anfang an, ein klassisches Büro zu mieten. Von dort können sowohl administrative Aufgaben als auch die Produktion selbst erfolgen. Der Vorteil der eigenen Produktion direkt vor Ort ist, dass wir ständig den Überblick der Qualität wahren.\nDer Standort hängt von verschiedenen Faktoren ab. Wichtig ist, dass das Büro sehr gut für Lieferanten und Post erreichbar ist, da wir die Zutaten von außerhalb beziehen und die Müslis Zunächst über unseren Online-Shop vertreiben werden. Auch wichtig ist, dass sich das Büro entweder im Erdgeschoss befindet oder ein Aufzug vorhanden ist, schließlich können wir nicht jeden Tag kiloweise Haferflocken in den 4. Stock schleppen.\nNach reichlicher Überlegung haben wir uns für Tübingen als Standort entschieden. Bei der Entscheidung hat vor allem unser Wohnort Ostfildern eine Rolle gespielt. Tübingen ist sowohl mit dem Auto als auch mit öffentlichen Verkehrsmitteln erreichbar. Im Gegensatz zu Stuttgart, was auch ein möglicher Standort wäre, ist Tübingen allerdings günstiger. Ein weiterer Vorteil wäre, dass wir direkt bei unserer Zielgruppe produzieren und somit auch ggf. Versandkosten wegfallen. Darüber hinaus ist auch die analoge Vernetzung mit den Kunden besser möglich.\nEin potentielles Büro ist momentan frei. Dieses hat eine direkte Anbindung zur B27. Fußläufig befinden sich zudem zwei Kantinen, ein Restaurant, sowie ein italienischer Imbiss. Zudem befindet sich ein DHL-Paketshop in unmittelbarer Nähe. Das Büro ist 26,6 m², dies genügt erst einmal, da Administrative Aufgaben von zuhause erledigt werden. Sollte ein Meeting mit potentiellen Partnern anstehen, ist es möglich kostenlos hochwertige Konferenzräume im Gebäude zu verwenden. Sobald das Büro nicht mehr ausreicht, gibt es dort auch Büros bis zu 69 m².",
        },
        {
          id: "3",
          name: "4.1. Zutaten\nAls kleines Müsli-Startup haben wir logischerweise noch keine großen Lieferanten, um an unsere Zutaten zu gelangen. Vorerst beziehen wir unsere Zutaten von verschiedenen Großhändlern. In Zukunft ist geplant, engere Partnerschaften mit ausgewählten Lieferanten einzugehen. Gegebenenfalls ist auch eine eigene Produktion von Zutaten denkbar.\n\n4.2. Ausstattung\nZur Ausstattung zählen neben Computern, Schreibutensilien, usw. auch die nötigen Füllmaschinen. Die Produktion kann am Anfang zwar noch manuell bedient werden, allerdings werden trotzdem Maschinen benötigt, die dies erleichtern. Zu diesen Maschinen gehören Füllmaschinen und ein Gerät zur Versiegelung der Verpackung.\n\n4.3. Personal\nViele Mitarbeiter sind erstmal aus finanziellen Gründen nicht möglich. Auch wenn so jeder der vier Gründer deutlich mehr macht als in einem normalen Unternehmen üblich, sind anfangs keine weiteren Mitarbeiter geplant. Allerdings ist es denkbar, u.a. in der Weihnachtszeit eine Hilfskraft einzustellen, um ein möglicherweise höheres Aufkommen an Bestellungen zu bewältigen. Die Hilfskraft und auch die vier Gründer müssen eine Hygieneschulung des Gesundheitsamtes besucht haben.",
        },
        {
          id: "4",
          name: "Aufgrund des bisher fehlenden Kapitals ist es noch nicht möglich, eine GmbH zu gründen. Eine Alternative stellt hier die Unternehmensgesellschaft (UG) dar. Der Vorteil ist, dass das Mindeststammkapital bei einem Euro liegt. Ein Nachteil ist allerdings, dass die Seriosität eines Unternehmens darunter leidet, da ein so geringes Stammkapital vorliegen kann. Sollte es Hanfonie allerdings gelingen, genug Kapital zu erlangen, um auch die nötigen 25.000 Euro Stammkapital für eine GmbH bereit zu stellen, ist es äußerst ratsam eine solche zu gründen.",
        },
        {
          id: "5",
          name: "Zu den wichtigsten Schlüsselpartnern zählen Influencer, welche die größte Rolle des Marketings übernehmen. In Bezug auf die Finanzierung von Hanfonie streben wir ein Investment des wundervollsten Investors aller Zeiten an: Herr Huber. Ein solches Investment wäre ganz klar die wichtigste Schlüsselpartnerschaft des Unternehmens.\nVorerst sind keine engeren Partnerschaften mit Zulieferern geplant, da dies als kleines Startup noch nicht realisierbar ist. Allerdings sind solche Schlüsselpartner im Falle einer steigenden Geschäftstätigkeit essenziell. ",
        },
        {
          id: "6",
          name: "Die Müslis von Hanfonie richten sich größtenteils an junge Menschen. Vor allem für Studenten mit dem Wunsch, ein gesundes Leben zu führen, eignen sich die Müslis hervorragend. Personen, die morgens gerne auch mal etwas länger frisches Obst und Gemüse in ein eigenes Müsli schneiden eignen sich allerdings nicht ideal als Kunden. Unsere Hanf Müslis eignen sich eher, um entspannt und ohne viel Aufwand in den Tag zu starten, dabei aber trotzdem eine gesunde und leckere Mahlzeit zu haben.",
        },
        {
          id: "7",
          name: "Der Kontakt zu den Kunden soll größtenteils Online erfolgen. Ziel ist, Instagram Posts und ggf. Produktplazierungen in YouTube Videos bei Influencern zu buchen, um die eher jüngere Zielgruppe zu erreichen. Auch ein eigener Instagram und TikTok Account ist geplant, um eine etwas persönlichere Beziehung zu erreichen. Die Website Hanfonie.de wird die größte Rolle bei dem Kontakt mit den Kunden sein. Über sie werden die Produkte bestellt und das Unternehmen vorgestellt. Je nach Lage und Art der Bestellung kann auch eine persönliche Lieferung erfolgen.",
        },
        {
          id: "8",
          name: "9.1. Kapital\nEinen Teil des benötigten Kapitals bringen die vier Gründer in Form von Eigenkapital in das Unternehmen ein. Dieser Betrag ist allerdings nicht genug, damit Hanfonie tatsächlich ins Geschäft starten kann. Daher planen wir, bei „Start-up BW Pre-Seed“ eine Förderung zu beantragen, um das Startkapital auszubauen. Die Sendung “Die Hölle der vomierenden Löwen” könnte eine weitere Finanzierungsmöglichkeit darstellen. Dabei investiert ein Investor für Geschäftsanteile in das Unternehmen.\n\n9.2. Erlösstruktur \nDie Folgenden Darstellungen zeigen die Materialkosten der Müslis für Hanfonie. Auf Basis der Kosten kommen die Preise zu Stande. Zu Anfang verzichten die Gründer auf ein reguläres Gehalt, um Kosten zu sparen. ",
        },
      ],
    };
  },
  methods: {
    setDescriptionText(id) {
      this.activeDescription = this.descriptions.find(
        (desc) => desc.id === id
      ).name;
    },
  },
  created()
  {
    this.activeDescription = this.descriptions[0].name;
  }
};
</script>

<style scoped>
.pre-formatted {
  white-space: pre-wrap;
}

@media (max-width: 1024) {
}
</style>

<template>
  <div>
    <AboutUs></AboutUs>
    <ProjectTexts class="py-16"></ProjectTexts>
  </div>
</template>

<script>
import AboutUs from "../components/about/AboutUs.vue";
import ProjectTexts from "../components/about/ProjectTexts.vue";

export default {
  name: "AboutView",
  components: {
    AboutUs: AboutUs,
    ProjectTexts: ProjectTexts,
  },
  mounted() {
    window.scrollTo(0, 0);
  }
};
</script>

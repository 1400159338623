<template>
    <div>
        <img src="@/assets/Compare.png">
    </div>
</template>

<script>
export default {
    name: 'CompareView',
    mounted() {
        window.scrollTo(0, 0);
    }
}
</script>

<style scoped>
div {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 10rem;
    padding-top: 2rem;
}
</style>
<template>
    <li class="flex justify-center items-center gap-4 flex-col mb-8" v-for="(section, index) in sections" :key="section.title">
        <h2 class="text-center">{{ (index+1) + '. ' + section.title }}</h2>
        <input class="px-2 outline-none border-0 border-b-2 border-neutral-500 bg-inherit" v-if="section.canSearch" type="text" v-model="search[index]">
        <ul class="list-none m-0 p-0 flex flex-row gap-4 overflow-x-auto overflow-y-hidden items-center max-w-[100%]" :class="{'scroll-view': section.canSearch, 'static-view': !section.canSearch}">
            <MixItSelection :options="section.options" :search="search[index]" v-model="chosenSelection[index]"></MixItSelection>
        </ul>
    </li>
</template>

<script>
import MixItSelection from './MixItSelection.vue';

export default {
    props: ['sections'],
    emits: ['valueChanged'],
    components: {
        MixItSelection: MixItSelection,
    },
    data() {
        return {
            chosenSelection: [],
            search: [],
        };
    },
    watch: {
        chosenSelection: {
            handler: function (newVal) {
                this.$emit('valueChanged', newVal);
            },
            deep: true
        }
    }
}
</script>

<style scoped>
.scroll-view {
    justify-content: flex-start;
}

.static-view {
    justify-content: center;
}
</style>
<template>
    <div class="flex justify-center items-center flex-col gap-8 py-8 px-4">
        <h1 class="text-4xl text-center font-medium">Erstelle deine Mischung</h1>
        <ul class="list-none m-0 p-0 max-w-[100%]">
            <MixItSection v-on:valueChanged="updatePrice" :sections="sections"></MixItSection>
        </ul>
        <div class="flex flex-col sm:flex-row items-center justify-center gap-4 sm:gap-6 bg-[#FFFFFF]/20 shadow rounded-lg border border-spacing-0 p-4">
            <h3 class="text-xl">{{ currentPrice }}€</h3>
            <ButtonStyle>
                <h4 class="text-white font-medium text-base text-center mx-2">Zum Warenkorb hinzufügen</h4>
            </ButtonStyle>
        </div>
    </div>
</template>

<script>
import MixItSection from '@/components/mixIt/MixItSection.vue';

export default {
    name: 'MixItView',
    inject: ['prices'],
    components: {
        MixItSection: MixItSection,
    },
    data() {
        return {
            sections: [
                {
                    title: 'Wähle deine Menge',
                    canSearch: false,
                    options: ['500g', '1000g']
                },
                {
                    title: 'Deine Basis Mischung',
                    canSearch: false,
                    options: ['Hanf + Haferflocken']
                },
                {
                    title: 'Deine erste Zutat',
                    canSearch: true,
                    options: ['Apfel', 'Ananas', 'Aprikose', 'Banane', 'Birne', 'Datteln', 'Pfirsiche', 'Pflaumen', 'Ingwer', 'Kiwi', 'Rosinen', 'Rhababer', 'Erdbeeren', 'Honigmelone'] 
                },
                {
                    title: 'Deine zweite Zutat',
                    canSearch: true,
                    options: ['Apfel', 'Ananas', 'Aprikose', 'Banane', 'Birne', 'Datteln', 'Pfirsiche', 'Pflaumen', 'Ingwer', 'Kiwi', 'Rosinen', 'Rhababer', 'Erdbeeren', 'Honigmelone'] 
                },
            ],
            currentPrice: '-',
            chosenSelection: [],
        };
    },
    methods: {
        updatePrice(newVal) {
            this.chosenSelection = newVal;
            const val = newVal[0];
            console.log(val);
            const prices = this.prices.find(price => price.category === 'mixit');
            if (val === '500g') {
                this.currentPrice = prices.small;
            } else if (val == '1000g') {
                this.currentPrice = prices.big;
            } else {
                this.currentPrice = '-';
            }
        },
    },
    mounted() {
        window.scrollTo(0, 0);
    }
}
</script>

<style scoped>
h1 {
    color: var(--clr-green-dark);
}
</style>